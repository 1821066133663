<template>
  <v-app id="landingPage">
    <Alert />
    <v-app-bar color="rgba(1,1,1,0.5)" dark dense fixed flat>
      <marquee>
        ආශිර්වාද මංගල සේවය සඳහා ලියාපදිංචිය සම්පූර්ණයෙන්ම නොමිලේ ! ඔබට විශ්වාසවන්ත හා ආරක්ෂාකාරී සේවාවක් උපරිමයෙන් ලබාදීම අපගේ බලාපොරොත්තුවයි ! ඔබට ගැළපෙන සහකරු/සහකාරිය ඉතා ඉක්මනින්
        අප සොයා දෙන්නෙමු. විදෙස්ගතව සිටින ඔබටද ආශිර්වාද සාමාජිකත්වය ලබාගත හැකිය. | අපව අමතන්න 071-792 8627 , 071-792 8628
      </marquee>
    </v-app-bar>
    <v-content>
      <v-container fluid style="padding: 0">
        <!-- Badge -->
        <v-img
          aspect-ratio="1"
          contain
          height="30%"
          id="badge"
          src="../assets/images/badge.png"
          width="30%"
        />

        <!-- Cover photo -->
        <v-img
          gradient="rgba(124, 197, 138, 0.7), rgba(0,0,0,.6)"
          height="100vh"
          id="cover-photo"
          src="../assets/images/cover.jpg"
        >
          <v-btn
            @click.stop="dialog = true"
            class="blink"
            color="white"
            elevation="24"
            id="btn-login"
            large
            rounded
          >
            <v-icon class="blink mr-2">mdi-login-variant</v-icon>ඇතුල්වන්න
          </v-btn>

          <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);">
            <h1
              :style="text1FontSize"
              class="font-arjun text-center"
              id="cover-photo-text-1"
            >wdYS¾jdo</h1>
            <h2
              :style="text2FontSize"
              class="font-arjun text-center"
              id="cover-photo-text-2"
            >ux., fiajh</h2>
          </div>

          <v-btn
            @click="$vuetify.goTo('#section1', scrollOptions)"
            class="bounce"
            color="white"
            icon
            style="position: absolute; left: 50%; top: 75%; transform: translate(-50%, -50%);"
          >
            <v-icon x-large>mdi-chevron-down</v-icon>
          </v-btn>

          <div :style="roundedRectangleStyle" id="rounded-rectangle"></div>

          <h3
            :style="text3FontSize"
            class="font-arjun text-center"
            id="cover-photo-text-3"
          >id¾:l hq. osúhl werUqu</h3>
        </v-img>

        <!-- Login modal -->
        <v-dialog eager max-width="500" transition="slide-y-transition" v-model="dialog">
          <v-card color="rgba(220,220,220, .95)">
            <v-card-title
              class="justify-center"
              style="font-size: 1.55em; font-family: Noto Sans Sinhala Light"
            >ගිණුමට ඇතුල්වන්න</v-card-title>
            <v-divider class="mt-2"></v-divider>
            <v-card-text>
              <v-form @submit.prevent="login">
                <v-text-field
                  class="my-4"
                  flat
                  hide-details
                  label="සාමාජික අංකය"
                  solo
                  v-model="credentials.username"
                ></v-text-field>
                <v-text-field
                  class="my-4"
                  flat
                  hide-details
                  label="මුරපදය"
                  solo
                  type="password"
                  v-model="credentials.password"
                ></v-text-field>
                <v-btn
                  :loading="loadingLoginBtn"
                  block
                  color="primary"
                  dark
                  depressed
                  large
                  type="submit"
                >ගිණුමට ඇතුල්වන්න</v-btn>
              </v-form>
              <div class="text-center mt-1">
                <v-btn :ripple="false" @click="showForgotPwdTxt = !showForgotPwdTxt" small text>
                  <span class="body-2 secondary--text">මුරපදය අමතකද ?</span>
                </v-btn>
                <p class="mb-0 ml-3" v-if="showForgotPwdTxt">071-792 8627 හෝ 071-792 8628 අමතන්න</p>
              </div>
            </v-card-text>
            <v-card-text>
              <v-row>
                <v-col sm="5">
                  <v-divider></v-divider>
                </v-col>
                <v-col sm="2">
                  <p
                    class="text-center"
                    style="font-size: 1.25em; border: 1px solid; border-radius: 1em; padding: 4px;"
                  >හෝ</p>
                </v-col>
                <v-col sm="5">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <!-- <p class="text-center mb-6" id="free-reg-text">නොමිලේ ලියාපදිංචිය සඳහා</p> -->
              <v-btn
                :loading="loadingRegBtn"
                @click="navigateToRegistrationPage"
                block
                color="yellow"
                elevation="12"
                large
              >
                <!-- <span style="font-weight: bold">මෙතැනින් ඇතුල් වන්න</span> -->
                <span id="free-reg-text">නොමිලේ ලියාපදිංචි වන්න</span>
              </v-btn>
              <!-- <p
                class="text-center green--text text--darken-2 mt-3 mb-0"
              >ඔබ මේ වන විටත් ආශිර්වාද මංගල සේවා ආයතනය තුල ලියාපදිංචි වී ඇත්නම් 071-792 8627 හෝ 071-792 8628 අමතා ඔබගේ රහස් අංකය ලබාගන්න</p>-->
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- Section 1 -->
        <div class="px-5 pt-5" id="section1">
          <v-lazy
            :options="{ threshold: 0.75  }"
            min-height="300"
            transition="scroll-y-reverse-transition"
            v-model="isActiveSection1"
          >
            <div>
              <h1 class="text-center pt-8 pb-5 section-header" style="color: #0277BD">wdhqfndajka</h1>
              <v-row id="intro-bg">
                <v-col cols="12" offset-sm="2" sm="8">
                  <h2
                    class="text-center"
                    id="intro"
                  >Tn l=uk rgl l=uk m%foaYhl Ôj;a jqj;a Tnf.a is;=ï me;=ï j,g Tnf.a ;rd;srug muKlau fkdj Tnf.a y|ykgo .e,fmk iylre fyda iyldßh fidhd.kak</h2>
                </v-col>
              </v-row>
            </div>
          </v-lazy>
        </div>

        <!-- Section 2 -->
        <div class="px-5 pt-10 pb-5" style="background-color:#fafafa">
          <v-lazy
            :options="{ threshold: 0.75  }"
            min-height="300"
            transition="scroll-y-reverse-transition"
            v-model="isActiveSection2"
          >
            <div>
              <h1 class="text-center section-header mb-10" style="color: #0277BD">idudðl .e,ßh</h1>

              <carousel
                :autoplayTimeout="7000"
                :paginationEnabled="false"
                :navigationEnabled="false"
                :perPageCustom="[[720, 3], [960, 4], [1264, 6]]"
                :autoplayHoverPause="true"
                :minSwipeDistance=1
                autoplay
                :loop='true'
                v-if="isMembersShow"
              >
                <slide :key="index" class="list-item" v-for="(member, index) in members">
                  <Member
                    :age="member.age"
                    :gender="member.gender"
                    :profilePicture="member.photoUrl"
                    :username="member.id"
                  />
                </slide>
              </carousel>

              <v-row justify="center" v-else>
                <v-progress-circular color="accent" indeterminate size="48"></v-progress-circular>
              </v-row>

              <v-row class="mt-10" justify="center" no-gutters>
                <toggle-switch
                  :options="toggleSwitchOptions"
                  :value="toggleValue"
                  @change="fetchMembers"
                />
              </v-row>
            </div>
          </v-lazy>
        </div>

        <!-- Section 3 -->
        <div class="px-5 my-10">
          <v-lazy
            :options="{ threshold: 0.75  }"
            min-height="300"
            transition="scroll-y-reverse-transition"
            v-model="isActiveSection3"
          >
            <div>
              <h1 class="text-center pb-5 section-header" style="color: #0277BD">Tng wfmka</h1>
              <v-row class="mx-sm-12">
                <v-col :key="i" cols="12" md="4" sm="12" v-for="(service, i) in services">
                  <Service :service="service" />
                </v-col>
              </v-row>
            </div>
          </v-lazy>
        </div>

        <!-- Section 4 -->
        <div class="px-5" style="background-color:#fafafa">
          <carousel
            :autoplayTimeout="4000"
            :paginationEnabled="false"
            :perPageCustom="[[360,1], [720, 3], [960, 4], [1264, 4]]"
            autoplay
            loop
          >
            <slide :key="i" class="list-item" v-for="(adv, i) in advertisements">
              <Advertisement :adObject="adv" @click="openAdvertisement(i)" style="cursor: pointer" />
            </slide>
          </carousel>
        </div>
      </v-container>
    </v-content>

    <!-- Footer -->
    <v-footer dark padless>
      <v-img
        gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
        min-height="40vh"
        src="../assets/images/footer_background.jpg"
        style="position: relative"
      >
        <v-container class="mt-md-12 mb-12 mb-md-0" fluid>
          <v-row no-gutters>
            <v-col class="d-flex flex-column justify-center pl-md-12" cols="12" md="6">
              <h4
                :style="text3FontSize"
                class="text-center font-arjun"
                style="font-size: 3em; color: #f19953"
              >id¾:l hq. Èúhl werUqu</h4>
              <h4
                :style="text4FontSize"
                class="text-center font-weight-thin roboto"
                style="letter-spacing: 5px!important"
              >Powered by ashirwada.lk</h4>
              <div class="d-flex justify-center caption font-weight-light roboto">
                <router-link style="color: inherit" target="_blank" to="/terms-and-conditions">
                  <p>Terms and Conditions</p>
                </router-link>
                <p class="mx-3">|</p>
                <router-link style="color: inherit" target="_blank" to="/privacy-policy">
                  <p>Privacy Policy</p>
                </router-link>
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" md="5">
              <v-row class="pl-md-12">
                <v-col class="shrink">
                  <v-icon class="contact-icons pa-3" color="white">mdi-cellphone-android</v-icon>
                </v-col>
                <v-col class="grow">
                  <p class="roboto mb-0">0717 928 627 / 0717 928 628</p>
                  <p class="roboto mb-0">Whatsapp / Viber / Imo</p>
                  <p class="roboto mb-0">( ප‌ෙ.ව 8.00 - ප.ව 5.00 )</p>
                </v-col>
              </v-row>
              <v-row class="pl-md-12">
                <v-col class="d-flex shrink">
                  <v-icon class="contact-icons pa-3" color="white">mdi-email</v-icon>
                </v-col>
                <v-col class="d-flex align-center grow pb-0">
                  <p class="roboto">ashirwadaproposals@gmail.com</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <p
          class="roboto text-center font-weight-light mb-0 py-4"
          style="position: absolute; width: 100%; bottom: 0; background-color: rgba(0,0,0,0.25)"
        >Copyright © {{ new Date().getFullYear() }} ashirwada.lk. All rights reserved.</p>
      </v-img>
    </v-footer>
  </v-app>
</template>

<script>
import { mapMutations } from "vuex";
import { SHOW_ALERT } from "../store/mutation-types";
import { UserService } from "../services/user.service";
import { ApiService } from "../services/api.service";
import Alert from '../components/Alert';
import Member from "../components/Home/Member";
import Service from "../components/Home/Service";
import Advertisement from "../components/Home/Advertisement";
import { Carousel, Slide } from "vue-carousel";
import ToggleSwitch from 'vuejs-toggle-switch'

export default {
  components: {
    Alert,
    Service,
    Advertisement,
    Member,
    Carousel,
    Slide,
    ToggleSwitch
  },

  data: () => ({
    isActiveSection1: false,
    isActiveSection2: false,
    isActiveSection3: false,
    dialog: false,
    loadingLoginBtn: false,
    loadingRegBtn: false,
    credentials: {
      username: "",
      password: ""
    },
    toggleValue: "",
    toggleSwitchOptions: {
      layout: {
        fontFamily: 'Noto Sans Sinhala Light',
        fontWeightSelected: 'regular',
        noBorder: true
      },
      size: {
        fontSize: 1,
        height: 2.5,
        padding: 0.45,
        width: 15
      },
      items: {
        preSelected: 'මනාලයෝ',
        labels: [
          { name: 'මනාලයෝ', color: 'white', backgroundColor: '#1E88E5' },
          { name: 'මනාලිය‌ෝ', color: 'white', backgroundColor: '#1E88E5' }
        ]
      }
    },
    services: [
      {
        text:
          "පෞද්ගලිකත්වය හා රහස්‍යභාවය සුරකිමින් වගකීමකින් යුතුව විශ්වාසනීය සේවාවක් සැපයීම",
        icon: "mdi-shield-check"
      },
      { text: "නොමිලයේ ලියාපදිංචිය ලබාගත හැකිවීම", icon: "mdi-account-plus" },
      {
        text:
          "අනන්‍යතාවය තහවුරු කල හැකි පුද්ගලයන්ට පමණක් සාමාජිකත්වය ලබාගත හැකිවීම",
        icon: "mdi-account-remove"
      }
    ],
    members: [],
    isMembersShow: true,
    advertisements: [],
    showForgotPwdTxt: false,
    // Scrolling data
    duration: 500,
    offset: 0,
    easing: 'easeInOutCubic',
  }),

  mounted() {
    this.fetchMembers({ value: "" });
    this.fetchAdvertisements();
  },

  computed: {
    text1FontSize: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size: 6em";
        case "sm":
          return "font-size: 8em";
        case "md":
          return "font-size: 10em";
        case "lg":
          return "font-size: 12em";
        case "xl":
          return "font-size: 14em";
        default:
          return "";
      }
    },
    text2FontSize: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size: 3.5em";
        case "sm":
          return "font-size: 4em";
        case "md":
          return "font-size: 4.5em";
        case "lg":
          return "font-size: 5em";
        case "xl":
          return "font-size: 6em";
        default:
          return "";
      }
    },
    text3FontSize: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size: 2em";
        case "sm":
          return "font-size: 2.25em";
        case "md":
          return "font-size: 2.5em";
        case "lg":
          return "font-size: 3em";
        case "xl":
          return "font-size: 4em";
        default:
          return "";
      }
    },
    text4FontSize: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size: 1.2em";
        case "sm":
          return "font-size: 1.5em";
        case "md":
          return "font-size: 1.8em";
        case "lg":
          return "font-size: 2.1em";
        case "xl":
          return "font-size: 2.4em";
        default:
          return "";
      }
    },
    roundedRectangleStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "height: 3.5em";
        case "sm":
          return "height: 4em";
        case "md":
          return "height: 4.5em";
        case "lg":
          return "height: 5em";
        case "xl":
          return "height: 5.5em";
        default:
          return "height: 5em";
      }
    },
    scrollOptions() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      }
    },
  },

  methods: {
    ...mapMutations({ showAlert: SHOW_ALERT }),

    login: async function () {
      this.loadingLoginBtn = true;
      try {
        const response = await UserService.login(this.credentials, 0); // Role = 0 (Customer)

        if (!response.data.approved) {
          this.$router.push("not-approved");
        } else if (response.data.isPackageExpired) {
          this.$router.push("package-expired");
        } else {
          this.$router.push("dashboard");
        }
      } catch (error) {
        this.showAlert({ message: "ඇතුළුවීම අසාර්ථකයි", type: "error" });
      }
      this.loadingLoginBtn = false;
    },

    navigateToRegistrationPage: async function () {
      this.loadingRegBtn = true;
      await this.$router.push("/register");
      this.loadingRegBtn = false;
    },

    fetchMembers: async function (event) {
      this.isMembersShow = false;
      let gender = "පුරුෂ";

      if (event.value == "මනාලිය‌ෝ") {
        gender = "ස්ත්‍රී";
      }

      try {
        const url = "customers/homepage-list";
        const params = { params: { gender: gender } };
        const response = await ApiService.get(url, params);
        this.members = response.data;
      } catch (error) {
        console.log(error);
      }
      this.isMembersShow = true;
    },

    fetchAdvertisements: async function () {
      try {
        const url = "files/download/advertisements";
        const response = await ApiService.get(url);
        this.advertisements = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    // Open the advertisement
    openAdvertisement: function (index) {
      const advertisement = this.advertisements[index];
      if (advertisement) {
        if (advertisement.website) {
          window.open(advertisement.website);
        } else {
          window.open(process.env.VUE_APP_BASE_URL + advertisement.file);
        }
      }
    }
  }
};
</script>

<style>
@-webkit-keyframes blink {
  0% {
    color: white;
  }
  100% {
    color: #ff5252;
  }
}
@keyframes blink {
  0% {
    color: white;
  }
  100% {
    color: #ff5252;
  }
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translate(-50%, 0);
  }
  40% {
    transform: translate(-50%, -30px);
  }
  60% {
    transform: translate(-50%, -15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translate(-50%, 0);
  }
  40% {
    transform: translate(-50%, -30px);
  }
  60% {
    transform: translate(-50%, -15px);
  }
}

#cover-photo {
  position: relative;
}
#btn-login {
  position: absolute;
  top: 15%;
  right: 5%;
}
#cover-photo-text-1 {
  color: white;
  text-shadow: 2px 2px 15px black;
}
#cover-photo-text-2 {
  margin-top: -10%;
  color: white;
  text-shadow: 1px 1px 15px black;
}
#cover-photo-text-3 {
  position: absolute;
  width: 100%;
  bottom: 0.15em;
  color: #f19953;
  /* text-shadow: 2px 2px 1px #eeeeee; */
}
#intro {
  font-size: 2.5em;
  font-family: FMDerana;
}
#rounded-rectangle {
  background: #eeeeee;
  position: absolute;
  bottom: 0;
  margin: auto;
  width: 100%;
  border-radius: 10vh 10vh 0 0;
  box-shadow: 1px 1px 50px 3px rgb(0, 0, 0);
}
#badge {
  position: fixed;
  bottom: 1vh;
  right: -6vw;
  z-index: 100;
}
#free-reg-text {
  text-shadow: 1px 1px 20px white;
  font-size: 1.1rem;
  letter-spacing: 2px;
  color: #ff5252;
  font-weight: bold;
}

.section-header {
  font-size: 4em;
  font-family: FMDerana;
}
.blink {
  color: white;
  animation: blink 0.5s ease-in infinite;
  -webkit-animation: blink 0.5s ease-in infinite;
}
.bounce {
  -webkit-animation: bounce 1s infinite;
  animation: bounce 1s infinite;
}
.v-switch-label {
  font-weight: inherit !important;
}
.contact-icons {
  border: 1px solid white;
  border-radius: 100%;
}
</style>