<template>
  <v-sheet class="text-center" color="rgba(0, 0, 0, 0)">
    <v-avatar size="96">
      <v-img
        :lazy-src="lazyImage"
        :src="image"
        aspect-ratio="1"
        class="mx-auto grey lighten-2"
        max-width="100"
      >
        <template v-slot:placeholder>
          <v-row align="center" class="fill-height ma-0" justify="center">
            <v-progress-circular class="mx-auto my-auto" color="grey lighten-5" indeterminate></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-avatar>
    <p style="font-size:14px">සාමාජික අංකය : {{ username }}</p>
    <p style="margin-top: -20px;">වයස : {{ age }}</p>
  </v-sheet>
</template>

<script>
import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';

export default {
  props: ['username', 'age', "gender", 'profilePicture', 'profPicShowStatus'],

  computed: {
    lazyImage: function () {
      if (this.gender == "ස්ත්‍රී") {
        return require("@/assets/images/female_user.png");
      } else {
        return require("@/assets/images/male_user.png");
      }
    },
    image: function () {
      if (this.profilePicture) {
        return process.env.VUE_APP_BASE_URL + this.profilePicture;
      } else {
        if (this.gender == "ස්ත්‍රී") {
          return require("@/assets/images/female_user.png");
        } else {
          return require("@/assets/images/male_user.png");
        }
      }
    }
  }
}
</script>