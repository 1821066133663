<template>
  <v-hover v-slot:default="{ hover }">
    <v-sheet
      :class="hover ? 'animate bounceOnce': ''"
      :color="hover ? 'primary': 'secondary'"
      class="d-flex flex-column custom-shadow"
      min-height="190px"
    >
      <v-icon class="pt-4" color="white" size="60">{{service.icon}}</v-icon>
      <p class="ma-5 text-center white--text">{{service.text}}</p>
    </v-sheet>
  </v-hover>
</template>

<script>
export default {
  props: ['service']
}
</script>

<style>
.animate {
  animation-duration: 0.5s;
}
@keyframes bounceOnce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
.bounceOnce {
  animation-name: bounceOnce;
}
</style>