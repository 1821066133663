<template>
  <v-img
    :key="image"
    :src="image"
    @click="$emit('click')"
    aspect-ratio="1.77"
    class="custom-shadow custom-radius mx-1 my-4"
    transition="scale-transition"
  >
    <template v-slot:placeholder>
      <v-row align="center" class="fill-height ma-0" justify="center">
        <v-progress-circular class="mx-auto my-auto" color="accent" indeterminate></v-progress-circular>
      </v-row>
    </template>
  </v-img>
</template>

<script>
export default {
  props: ['adObject'],

  computed: {
    image: function () {
      try {
        return process.env.VUE_APP_BASE_URL + this.adObject.file;
      } catch (error) {
        return require("../../assets/images/ad.jpg");
      }
    }
  }
}
</script>